import React, { useState } from 'react'
import {
    makeStyles,
    Button,
    Grid,
    Typography,
    Paper,
    Box,
} from '@material-ui/core'
import { Add as AddIcon, Send as SendIcon } from '@material-ui/icons'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(4),
    },
    headerSection: {
        marginTop: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    title: {
        fontWeight: 700,
        color: '#4255FF',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    text: {
        color: '#4B5563',
        fontSize: '0.875rem',
        '@media (min-width: 768px)': {
            fontSize: '1rem',
        },
        maxWidth: '36rem',
    },
    button: {
        borderRadius: 50,
        padding: theme.spacing(1.5, 4),
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '1rem',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    addButton: {
        backgroundColor: '#4255FF',
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: '#3344DD',
            boxShadow: 'none',
        },
    },
    dateFilterSection: {
        padding: theme.spacing(3),
        borderRadius: 16,
        backgroundColor: '#F8F9FC',
    },
    dateFilterTitle: {
        fontSize: '1.5rem',
        fontWeight: 600,
        color: '#1a2b3b',
        marginBottom: theme.spacing(3),
    },
    datePickerContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    datePicker: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 240,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 12,
            backgroundColor: '#fff',
            '& fieldset': {
                borderColor: '#e0e0e0',
            },
            '&:hover fieldset': {
                borderColor: '#bdbdbd',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '16px',
        },
    },
    dateLabel: {
        fontSize: '1rem',
        fontWeight: 500,
        color: '#1a2b3b',
        marginBottom: theme.spacing(1),
    },
    sendButton: {
        backgroundColor: '#4255FF',
        color: '#fff',
        borderRadius: 8,
        padding: theme.spacing(1.5, 4),
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: 600,
        marginTop: '16px',
        height: '56px',
        '&:hover': {
            backgroundColor: '#3344DD',
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            width: '100%',
        },
    },
}))

export default function LeadsHeader({ setOpenForm }) {
    const classes = useStyles()
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)

    const handleSend = () => {
        console.log('Sending leads between:', fromDate, 'and', toDate)
    }

    return (
        <div className={classes.root}>
            <Paper elevation={0} className={classes.dateFilterSection}>
                <Typography variant="h5" className={classes.dateFilterTitle}>
                    Send Leads
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box className={classes.datePickerContainer}>
                        <div>
                            <Typography className={classes.dateLabel}>
                                From Date
                            </Typography>
                            <KeyboardDatePicker
                                margin="normal"
                                id="from-date"
                                format="dd/MM/yyyy"
                                value={fromDate}
                                onChange={setFromDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change from date',
                                }}
                                className={classes.datePicker}
                                inputVariant="outlined"
                                placeholder="dd/mm/yyyy"
                            />
                        </div>
                        <div>
                            <Typography className={classes.dateLabel}>
                                To Date
                            </Typography>
                            <KeyboardDatePicker
                                margin="normal"
                                id="to-date"
                                format="dd/MM/yyyy"
                                value={toDate}
                                onChange={setToDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change to date',
                                }}
                                className={classes.datePicker}
                                inputVariant="outlined"
                                placeholder="dd/mm/yyyy"
                            />
                        </div>
                        <Button
                            variant="contained"
                            onClick={handleSend}
                            className={classes.sendButton}
                            startIcon={<SendIcon />}
                        >
                            Send
                        </Button>
                    </Box>
                </MuiPickersUtilsProvider>
            </Paper>

            <Grid
                container
                spacing={3}
                className={classes.headerSection}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={12} sm="auto">
                    <Typography
                        variant="h4"
                        component="h1"
                        className={classes.title}
                    >
                        Contact Details For Leads
                    </Typography>
                    <p className={classes.text}>
                        The listed email addresses will receive daily leads
                        registered on English Hub.
                    </p>
                </Grid>
                <Grid item xs={12} sm="auto">
                    <Button
                        onClick={() => setOpenForm(true)}
                        variant="contained"
                        className={`${classes.button} ${classes.addButton}`}
                        startIcon={<AddIcon />}
                    >
                        Add New Lead
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}
