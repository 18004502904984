import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Typography,
    Box,
    Switch,
    FormControlLabel,
    makeStyles,
} from '@material-ui/core'
import RemoteServices from 'RemoteServices/RemoteServices'

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: 16,
        },
    },
    dialogTitle: {
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        '& h2': {
            fontSize: '1.75rem',
            fontWeight: 600,
            color: '#1a2b3b',
        },
    },
    dialogContent: {
        padding: theme.spacing(3),
        paddingTop: 0,
    },
    dialogActions: {
        padding: theme.spacing(2, 3),
        borderTop: '1px solid #e0e0e0',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },
    fieldLabel: {
        marginBottom: theme.spacing(1),
        color: '#1a2b3b',
        fontSize: '1rem',
        fontWeight: 500,
    },
    formField: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 12,
            backgroundColor: '#fff',
            '& fieldset': {
                borderColor: '#e0e0e0',
            },
            '&:hover fieldset': {
                borderColor: '#bdbdbd',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '16px',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#9e9e9e',
                opacity: 1,
            },
        },
    },
    switchLabel: {
        marginLeft: 0,
        '& .MuiFormControlLabel-label': {
            fontSize: '1rem',
            color: '#1a2b3b',
        },
        '& .MuiSwitch-root': {
            width: 56,
            height: 32,
            padding: 0,
            marginRight: theme.spacing(2),
        },
        '& .MuiSwitch-switchBase': {
            padding: 4,
            '&.Mui-checked': {
                transform: 'translateX(24px)',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#4285f4',
                    opacity: 1,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            width: 24,
            height: 24,
        },
        '& .MuiSwitch-track': {
            borderRadius: 16,
            backgroundColor: '#e0e0e0',
            opacity: 1,
        },
    },
    createButton: {
        backgroundColor: '#4285f4',
        color: '#fff',
        textTransform: 'none',
        fontSize: '1rem',
        padding: '8px 24px',
        borderRadius: 8,
        '&:hover': {
            backgroundColor: '#3367d6',
        },
    },
    cancelButton: {
        color: '#5f6368',
        textTransform: 'none',
        fontSize: '1rem',
    },
}))

const CreateEditLead = ({
    open,
    setOpenForm,
    setRefetch,
    enqueueSnackbar,
    type,
    leadDetails,
}) => {
    console.log('leadDetails', leadDetails)
    const classes = useStyles()
    const [newLeadLoading, setNewLeadLoading] = React.useState(false)
    const [formValues, setFormValues] = React.useState({
        full_name: leadDetails?.full_name || '',
        email: leadDetails?.email || '',
        is_active: leadDetails?.is_active || true,
    })

    React.useEffect(() => {
        setFormValues({
            full_name: leadDetails?.full_name || '',
            email: leadDetails?.email || '',
            is_active: leadDetails?.is_active ?? true,
        })
    }, [leadDetails])

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const handleSwitchChange = (event) => {
        setFormValues((prev) => ({ ...prev, is_active: event.target.checked }))
    }

    const onClose = () => {
        setOpenForm(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (!formValues.full_name) {
            enqueueSnackbar('Full name is required', { variant: 'error' })
            return
        }
        if (!formValues.email) {
            enqueueSnackbar('Email is required', { variant: 'error' })
            return
        }
        try {
            setNewLeadLoading(true)
            if (type === 'edit') {
                await RemoteServices.updateLead(leadDetails?.id, formValues)
                enqueueSnackbar(`Lead updated successfully!`, {
                    variant: 'success',
                })
            } else if (type === 'create') {
                await RemoteServices.postLead(formValues)
                enqueueSnackbar(`New lead created successfully!`, {
                    variant: 'success',
                })
            }

            setRefetch(true)
            onClose()
            setFormValues({
                full_name: '',
                email: '',
                is_active: true,
            })
        } catch (err) {
            enqueueSnackbar(err, { variant: 'error' })
        } finally {
            setNewLeadLoading(false)
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth
            className={classes.dialog}
        >
            <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                {type === 'create' ? 'Create New Lead' : 'Update Lead'}
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent className={classes.dialogContent}>
                    <Box className={classes.form}>
                        <div>
                            <Typography className={classes.fieldLabel}>
                                Full Name
                            </Typography>
                            <TextField
                                name="full_name"
                                autoFocus
                                fullWidth
                                value={formValues.full_name}
                                onChange={handleInputChange}
                                variant="outlined"
                                placeholder="Enter full name"
                                className={classes.formField}
                                InputProps={{
                                    'aria-label': 'Full name',
                                }}
                            />
                        </div>

                        <div>
                            <Typography className={classes.fieldLabel}>
                                Email
                            </Typography>
                            <TextField
                                name="email"
                                fullWidth
                                value={formValues.email}
                                onChange={handleInputChange}
                                variant="outlined"
                                placeholder="Enter email address"
                                className={classes.formField}
                                InputProps={{
                                    'aria-label': 'Email address',
                                }}
                            />
                        </div>

                        <FormControlLabel
                            control={
                                <Switch
                                    name="is_active"
                                    checked={formValues.is_active}
                                    onChange={handleSwitchChange}
                                    color="primary"
                                />
                            }
                            label="Active"
                            className={classes.switchLabel}
                        />
                    </Box>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={onClose} className={classes.cancelButton}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        loading={newLeadLoading}
                        disabled={newLeadLoading}
                        className={classes.createButton}
                    >
                        {type === 'create' ? 'Create Lead' : 'Update Lead'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default CreateEditLead
