import React from 'react'
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Typography,
    fade,
    Chip,
    CircularProgress,
} from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import EmptyLeadsState from './EmptyLeads'
import Loading from 'components/Loading'
import CreateEditLead from './LeadsCreateEdit'
import RemoteServices from 'RemoteServices/RemoteServices'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        borderRadius: theme.shape.borderRadius * 2,
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .05)',
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        backgroundColor: fade(theme.palette.primary.main, 0.1),
    },
    tableHeadCell: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: '0.95rem',
    },
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: fade(theme.palette.action.hover, 0.05),
        },
        '&:hover': {
            backgroundColor: fade(theme.palette.action.hover, 0.1),
        },
    },
    actionCell: {
        width: 120,
        textAlign: 'right',
    },
    iconButton: {
        padding: theme.spacing(1),
    },
    editButton: {
        color: theme.palette.info.main,
    },
    deleteButton: {
        color: theme.palette.error.main,
    },
    statusChip: {
        fontWeight: 600,
    },
    activeChip: {
        backgroundColor: fade(theme.palette.success.main, 0.1),
        color: theme.palette.success.dark,
    },
    inactiveChip: {
        backgroundColor: fade(theme.palette.error.main, 0.1),
        color: theme.palette.error.dark,
    },
}))

export default function LeadsTable({
    leads,
    isLeadsLoading,
    setOpenCreateForm,
    setRefetch,
    enqueueSnackbar,
}) {
    const classes = useStyles()

    if (isLeadsLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50dvh',
                }}
            >
                <Loading />
            </div>
        )
    }

    if (leads?.length === 0 || leads === null) {
        return <EmptyLeadsState setOpenCreateForm={setOpenCreateForm} />
    }

    return (
        <TableContainer component={Paper} className={classes.root}>
            <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell className={classes.tableHeadCell}>
                            Name
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                            Email
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                            Status
                        </TableCell>
                        <TableCell
                            className={`${classes.tableHeadCell} ${classes.actionCell}`}
                        >
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {leads?.map((lead) => (
                        <LeadRow
                            key={lead.id}
                            lead={lead}
                            setRefetch={setRefetch}
                            enqueueSnackbar={enqueueSnackbar}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const LeadRow = ({ lead, setRefetch, enqueueSnackbar }) => {
    const classes = useStyles()
    const [showEditDialog, setShowEditDialog] = React.useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)

    const handleDelete = async (id) => {
        try {
            setIsDeleteLoading(true)
            await RemoteServices.deleteLead(id)
            enqueueSnackbar(`Lead #${id} deleted successfully!`, {
                variant: 'success',
            })
            setRefetch(true)
        } catch (err) {
            enqueueSnackbar(err, { variant: 'error' })
        } finally {
            setIsDeleteLoading(false)
        }
    }
    return (
        <>
            <TableRow className={classes.tableRow}>
                <TableCell component="th" scope="row">
                    <Typography variant="body1">{lead.full_name}</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="body2" color="textSecondary">
                        {lead.email}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Chip
                        label={lead.is_active ? 'Active' : 'Inactive'}
                        size="small"
                        className={`${classes.statusChip} ${
                            lead.is_active
                                ? classes.activeChip
                                : classes.inactiveChip
                        }`}
                    />
                </TableCell>
                <TableCell className={classes.actionCell}>
                    <IconButton
                        className={`${classes.iconButton} ${classes.editButton}`}
                        size="small"
                        onClick={() => setShowEditDialog(true)}
                        aria-label="edit"
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                        className={`${classes.iconButton} ${classes.deleteButton}`}
                        size="small"
                        disabled={isDeleteLoading}
                        onClick={() => handleDelete(lead.id)}
                        aria-label="delete"
                    >
                        {isDeleteLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            <DeleteIcon fontSize="small" />
                        )}
                    </IconButton>
                </TableCell>
            </TableRow>
            <CreateEditLead
                key={lead.id}
                open={showEditDialog}
                setOpenForm={setShowEditDialog}
                setRefetch={setRefetch}
                enqueueSnackbar={enqueueSnackbar}
                type={'edit'}
                leadDetails={lead}
            />
        </>
    )
}
