import React from 'react'
import { makeStyles, Typography, Button, Paper } from '@material-ui/core'
import {
    Add as AddIcon,
    AssignmentTurnedIn as AssignmentIcon,
} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: theme.shape.borderRadius * 2,
        backgroundColor: theme.palette.background.default,
    },
    icon: {
        fontSize: 64,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(2),
        fontWeight: 600,
    },
    description: {
        marginBottom: theme.spacing(4),
        maxWidth: 400,
    },
    button: {
        borderRadius: theme.shape.borderRadius * 5,
        padding: theme.spacing(1, 3),
        fontWeight: 600,
    },
}))

const EmptyLeadsState = ({ setOpenCreateForm }) => {
    const classes = useStyles()

    return (
        <Paper elevation={0} className={classes.root}>
            <AssignmentIcon className={classes.icon} />
            <Typography variant="h5" component="h2" className={classes.title}>
                No Leads Yet
            </Typography>
            <Typography
                variant="body1"
                color="textSecondary"
                className={classes.description}
            >
                You haven't added any leads to your list. Start by adding a new
                lead to get things rolling!
            </Typography>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setOpenCreateForm(true)}
                className={classes.button}
            >
                Add New
            </Button>
        </Paper>
    )
}

export default EmptyLeadsState
