import React from 'react'
import Header from 'components/Headers/Header.js'
import {
    Button,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
} from 'reactstrap'
import Services from '../../RemoteServices/RemoteServices'
import { withSnackbar } from 'notistack'
import LeadsPage from 'views/leads/LeadsPage'

class Leads extends React.PureComponent {
    state = {
        fromDate: '',
        toDate: '',
    }

    sendLeads = () => {
        let reqDto = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
        }
        Services.sendLeads(reqDto)
            .then((response) => {
                this.props.enqueueSnackbar('Leads Submitted Successfully', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
            })
            .catch((error) => {
                this.props.enqueueSnackbar(
                    error ||
                        'Error! Please try again later or contact administrator',
                    {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                    }
                )
            })
    }

    handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    render() {
        return (
            <>
                <Header page={'lesson'} />
                <Container
                    className={'mt-4'}
                    style={{ minHeight: '480px', display: '' }}
                >
                    <Form
                        role="form"
                        style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                            maxWidth: '1000px',
                            width: '100%',
                        }}
                    >
                        <div className="lessonEditTitleDiv">
                            <h3
                                style={{ marginBottom: '20px' }}
                                className="modal-title"
                                id="exampleModalLabel"
                            >
                                Send Leads
                            </h3>
                        </div>

                        <FormGroup
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                gap: '20px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '30%',
                                }}
                            >
                                <Label className={'font-weight-bold'}>
                                    From Date
                                </Label>
                                <InputGroup
                                    style={{
                                        width: '100%',
                                        marginRight: '10px',
                                    }}
                                    className="input-group-alternative mb-3"
                                >
                                    <Input
                                        type={'date'}
                                        placeholder="Lesson Number"
                                        name="fromDate"
                                        value={this.state.fromDate}
                                        onChange={this.handleInputChange}
                                    />
                                </InputGroup>
                                {/*<Label className={'font-weight-bold'}>From Time</Label>*/}
                                {/*<InputGroup style={{ width: '100%', marginRight: '10px' }}*/}
                                {/*            className='input-group-alternative mb-3'>*/}
                                {/*    <Input type={'time'} placeholder='Lesson Number' name='fromDate'*/}
                                {/*           value={this.state.fromDate} onChange={this.handleInputChange} />*/}
                                {/*</InputGroup>*/}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '30%',
                                }}
                            >
                                <Label className={'font-weight-bold'}>
                                    To Date
                                </Label>
                                <InputGroup
                                    style={{
                                        width: '100%',
                                        marginRight: '10px',
                                    }}
                                    className="input-group-alternative mb-3"
                                >
                                    <Input
                                        type={'date'}
                                        placeholder="Lesson Title"
                                        name="toDate"
                                        value={this.state.toDate}
                                        onChange={this.handleInputChange}
                                    />
                                </InputGroup>
                            </div>
                        </FormGroup>
                        <div className="pb-4">
                            <Button
                                color="primary"
                                type="button"
                                onClick={this.sendLeads}
                            >
                                Send
                            </Button>
                        </div>
                    </Form>
                </Container>
                <LeadsPage />
            </>
        )
    }
}

export default withSnackbar(Leads)
