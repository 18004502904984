import React from 'react'
import { Container, Paper, makeStyles } from '@material-ui/core'
import LeadsHeader from './LeadsHeader'
import LeadsTable from './LeadsTable'
import { useFetchLeads } from './leads-services'
import CreateEditLead from './LeadsCreateEdit'
import { useSnackbar } from 'notistack'
import Header from 'components/Headers/Header'

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius * 2,
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .05)',
    },
}))

export default function LeadsPage() {
    const classes = useStyles()
    const { leads, isLeadsLoading, setRefetch } = useFetchLeads()
    const [openForm, setOpenForm] = React.useState(false)
    const { enqueueSnackbar } = useSnackbar()
    return (
        <>
            <Header page={'lesson'} />

            <Container maxWidth="lg" className={classes.root}>
                <Paper elevation={0} className={classes.paper}>
                    <LeadsHeader setOpenForm={setOpenForm} />
                    <LeadsTable
                        leads={leads}
                        isLoading={isLeadsLoading}
                        setOpenCreateForm={setOpenForm}
                        setRefetch={setRefetch}
                        enqueueSnackbar={enqueueSnackbar}
                    />
                </Paper>
                <CreateEditLead
                    type={'create'}
                    open={openForm}
                    setOpenForm={setOpenForm}
                    setRefetch={setRefetch}
                    enqueueSnackbar={enqueueSnackbar}
                />
            </Container>
        </>
    )
}
