import { useSnackbar } from 'notistack'
import React from 'react'
import RemoteServices from 'RemoteServices/RemoteServices'

const useFetchLeads = () => {
    const [leads, setLeads] = React.useState(null)
    const [isLeadsLoading, setIsLeadsLoading] = React.useState(false)
    const [refetch, setRefetch] = React.useState(false)
    const { enqueueSnackbar } = useSnackbar()

    React.useEffect(() => {
        async function fetchLeads() {
            try {
                setIsLeadsLoading(true)
                const data = await RemoteServices.getLeads()
                if (data) {
                    setLeads(data)
                }
            } catch (err) {
                enqueueSnackbar(err, { variant: 'error' })
            } finally {
                setIsLeadsLoading(false)
                setRefetch(false)
            }
        }
        fetchLeads()
    }, [refetch])

    return { leads, isLeadsLoading, setRefetch }
}

export { useFetchLeads }
