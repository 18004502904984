import Header from 'components/Headers/Header'
import React from 'react'
import {
    Add,
    Delete,
    Edit,
    Event as EventIcon,
    Group,
    Link,
    Lock,
    LockOpen,
    Person,
    Public,
    School,
    Share,
    Videocam,
    FileCopy,
} from '@material-ui/icons'
import {
    Container,
    Box,
    Typography,
    Button,
    IconButton,
    CircularProgress,
    Grid,
    Card,
    CardContent,
    Chip,
    makeStyles,
    Paper,
    Switch,
    Dialog,
    DialogTitle,
    DialogActions,
    FormControlLabel,
    TextField,
    Radio,
    RadioGroup,
    DialogContent,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'
import RemoteServices from 'RemoteServices/RemoteServices'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    card: {
        margin: '20px auto',
        padding: theme.spacing(2),
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    publicLabel: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0.5),
        color: '#43a047',
        '& svg': {
            fontSize: '1.2rem',
        },
    },
    meetingId: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1),
    },
    participants: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    timeInfo: {
        textAlign: 'right',
        color: theme.palette.text.secondary,
    },
    accessibleChip: {
        backgroundColor: '#e8f5e9',
        color: '#2e7d32',
        fontWeight: 500,
        borderRadius: 16,
    },
    restrictedChip: {
        backgroundColor: '#ffc9c9',
        color: '#ff5252',
        fontWeight: 500,
        borderRadius: 16,
    },
    publicChip: {
        backgroundColor: '#E6E6FA',
        color: '#4B0082',
        fontWeight: 500,
        borderRadius: 16,
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: theme.spacing(3),
        borderTop: `1px solid ${theme.palette.divider}`,
        paddingTop: theme.spacing(2),
    },
    leftActions: {
        display: 'flex',
        gap: theme.spacing(1),
    },
    rightSection: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    joinButton: {
        backgroundColor: '#4285f4',
        color: 'white',
        textTransform: 'none',
        padding: '8px 24px',
        '&:hover': {
            backgroundColor: '#3367d6',
        },
        '& .MuiButton-startIcon': {
            marginRight: theme.spacing(1),
        },
    },
}))

const useFormDialogStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: 16,
        },
    },
    dialogTitle: {
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        '& h2': {
            fontSize: '1.75rem',
            fontWeight: 600,
            color: '#1a2b3b',
        },
    },
    dialogContent: {
        padding: theme.spacing(3),
        paddingTop: 0,
    },
    dialogActions: {
        padding: theme.spacing(2, 3),
        borderTop: '1px solid #e0e0e0',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },
    fieldLabel: {
        marginBottom: theme.spacing(1),
        color: '#1a2b3b',
        fontSize: '1rem',
        fontWeight: 500,
    },
    formField: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 12,
            backgroundColor: '#fff',
            '& fieldset': {
                borderColor: '#e0e0e0',
            },
            '&:hover fieldset': {
                borderColor: '#bdbdbd',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '16px',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#9e9e9e',
                opacity: 1,
            },
        },
        '& .MuiInputAdornment-root': {
            marginLeft: theme.spacing(1),
            '& .MuiSvgIcon-root': {
                color: '#9e9e9e',
            },
        },
    },
    radioGroup: {
        flexDirection: 'row',
        gap: theme.spacing(4),
        '& .MuiFormControlLabel-root': {
            marginLeft: 0,
            marginRight: 0,
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '1rem',
            color: '#1a2b3b',
        },
        '& .MuiRadio-root': {
            color: '#bdbdbd',
            '&.Mui-checked': {
                color: '#4285f4',
            },
        },
    },
    switchLabel: {
        marginLeft: 0,
        '& .MuiFormControlLabel-label': {
            fontSize: '1rem',
            color: '#1a2b3b',
        },
        '& .MuiSwitch-root': {
            width: 56,
            height: 32,
            padding: 0,
            marginRight: theme.spacing(2),
        },
        '& .MuiSwitch-switchBase': {
            padding: 4,
            '&.Mui-checked': {
                transform: 'translateX(24px)',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#4285f4',
                    opacity: 1,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            width: 24,
            height: 24,
        },
        '& .MuiSwitch-track': {
            borderRadius: 16,
            backgroundColor: '#e0e0e0',
            opacity: 1,
        },
    },
    createButton: {
        backgroundColor: '#4285f4',
        color: '#fff',
        textTransform: 'none',
        fontSize: '1rem',
        padding: '8px 24px',
        borderRadius: 8,
        '&:hover': {
            backgroundColor: '#3367d6',
        },
    },
    cancelButton: {
        color: '#5f6368',
        textTransform: 'none',
        fontSize: '1rem',
    },
}))

const useMeetingShareStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    icon: {
        color: theme.palette.primary.main,
    },
}))

const TeacherSupport = () => {
    const [meetings, setMeetings] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(false)
    const [refetch, setRefetch] = React.useState(false)
    const { enqueueSnackbar } = useSnackbar()

    React.useEffect(() => {
        async function fetchMeetings() {
            try {
                setIsLoading(true)
                const data = await RemoteServices.getMeetingLinks()
                if (data) {
                    setMeetings(data)
                }
            } catch (err) {
                enqueueSnackbar(err, { variant: 'error' })
            } finally {
                setIsLoading(false)
                setRefetch(false)
            }
        }
        fetchMeetings()
    }, [refetch])

    return (
        <>
            <Header page={'lesson'} />
            <MeetingList
                {...{
                    meetings,
                    isLoading,
                    setRefetch,
                    enqueueSnackbar,
                }}
            />
        </>
    )
}

function MeetingList({ meetings, isLoading, setRefetch, enqueueSnackbar }) {
    const classes = useStyles()
    const [openForm, setOpenForm] = React.useState(false)

    return (
        <Container className={classes.root}>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 4,
                }}
            >
                <Typography
                    variant="h5"
                    component="h1"
                    align="center"
                    className={classes.title}
                >
                    Your Meeting Rooms
                </Typography>
                <Button
                    style={{ display: 'flex', alignItems: 'center', gap: 2 }}
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<Add />}
                    onClick={() => setOpenForm(true)}
                >
                    Create New Meeting
                </Button>
            </Box>
            <Grid container spacing={2}>
                {isLoading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            paddingTop: '3rem',
                        }}
                    >
                        <CircularProgress size={40} />
                    </Box>
                ) : meetings & meetings?.length ? (
                    <EmptyMeetingsScreen setOpenForm={setOpenForm} />
                ) : (
                    meetings?.map((meeting, index) => (
                        <Grid item xs={12} md={6} key={index}>
                            <Paper elevation={3}>
                                <MeetingCard
                                    {...{
                                        meeting,
                                        setRefetch,
                                        enqueueSnackbar,
                                    }}
                                />
                            </Paper>
                        </Grid>
                    ))
                )}
            </Grid>
            <MeetingFormDialog
                type={'create'}
                {...{
                    openForm,
                    setOpenForm,
                    setRefetch,
                    enqueueSnackbar,
                }}
            />
        </Container>
    )
}

const MeetingFormDialog = ({
    openForm,
    setOpenForm,
    setRefetch,
    enqueueSnackbar,
    type,
    meetingDetails,
}) => {
    const classes = useFormDialogStyles()
    const [newMeetingLoading, setNewMeetingLoading] = React.useState(false)
    const [formValues, setFormValues] = React.useState({
        name: meetingDetails?.name || '',
        meeting_type: meetingDetails?.meeting_type || 'public',
        meeting_start_time: meetingDetails?.meeting_start_time || '',
        is_visible_to_student: Boolean(meetingDetails?.is_visible_to_student),
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const onClose = () => {
        setOpenForm(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!formValues.name) {
            enqueueSnackbar('Please enter meeting name', {
                variant: 'error',
            })
            return
        }
        try {
            setNewMeetingLoading(true)
            const segment = () => Math.random().toString(36).substring(2, 5)
            const meetingId = `${segment()}-${segment()}-${segment()}`
            const visibleToStudent =
                formValues.meeting_type === 'public'
                    ? false
                    : formValues.is_visible_to_student
            if (type === 'edit') {
                await RemoteServices.updateMeeting(meetingDetails?.id, {
                    ...formValues,
                    meeting_id: meetingDetails?.meeting_id,
                    is_visible_to_student: visibleToStudent,
                })
                enqueueSnackbar(`Meeting updated successfully!`, {
                    variant: 'success',
                })
            } else if (type === 'create') {
                await RemoteServices.postNewMeeting({
                    meeting_id: meetingId,
                    ...formValues,
                    is_visible_to_student: visibleToStudent,
                })
                enqueueSnackbar(`New meeting created successfully!`, {
                    variant: 'success',
                })
            }
            setRefetch(true)
            onClose()
            setFormValues({
                name: '',
                meeting_type: 'public',
                meeting_start_time: '',
                is_visible_to_student: false,
            })
        } catch (err) {
            enqueueSnackbar(err, { variant: 'error' })
        } finally {
            setNewMeetingLoading(false)
        }
    }

    function removeTimezone(dateTime) {
        if (!dateTime) return ''
        const date = new Date(dateTime)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')

        return `${year}-${month}-${day}T${hours}:${minutes}`
    }

    return (
        <Dialog
            open={openForm}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth
            className={classes.dialog}
        >
            <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                {type === 'create' ? 'Create a New Meeting' : 'Update Meeting'}
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent className={classes.dialogContent}>
                    <Box className={classes.form}>
                        <div>
                            <Typography className={classes.fieldLabel}>
                                Meeting Name
                            </Typography>

                            <TextField
                                name="name"
                                autoFocus
                                fullWidth
                                value={formValues.name}
                                onChange={handleInputChange}
                                variant="outlined"
                                placeholder="Enter meeting name"
                                className={classes.formField}
                                InputProps={{
                                    'aria-label': 'Meeting name',
                                }}
                            />
                        </div>

                        <div>
                            <Typography className={classes.fieldLabel}>
                                Meeting Type
                            </Typography>
                            <RadioGroup
                                aria-label="meeting-type"
                                name="meeting_type"
                                value={formValues.meeting_type}
                                onChange={handleInputChange}
                                className={classes.radioGroup}
                            >
                                <FormControlLabel
                                    value="public"
                                    control={<Radio />}
                                    label="Public"
                                />
                                <FormControlLabel
                                    value="private"
                                    control={<Radio />}
                                    label="Private"
                                />
                                <FormControlLabel
                                    value="both"
                                    control={<Radio />}
                                    label="Both"
                                />
                            </RadioGroup>
                        </div>

                        <div>
                            <Typography className={classes.fieldLabel}>
                                Meeting Start Time
                            </Typography>
                            <TextField
                                variant="outlined"
                                value={removeTimezone(
                                    formValues.meeting_start_time
                                )}
                                onChange={handleInputChange}
                                name="meeting_start_time"
                                type="datetime-local"
                                style={{ width: '100%' }}
                                className={`${classes.formField}`}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        {formValues.meeting_type !== 'public' ? (
                            <FormControlLabel
                                control={
                                    <Switch
                                        name="is_visible_to_student"
                                        checked={
                                            formValues.is_visible_to_student
                                        }
                                        onChange={() => {
                                            setFormValues({
                                                ...formValues,
                                                is_visible_to_student:
                                                    !formValues.is_visible_to_student,
                                            })
                                        }}
                                    />
                                }
                                label="Visible to students"
                                className={classes.switchLabel}
                            />
                        ) : null}
                    </Box>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={onClose} className={classes.cancelButton}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        disabled={newMeetingLoading}
                        loading={newMeetingLoading}
                        className={classes.createButton}
                    >
                        {type === 'create'
                            ? 'Create Meeting'
                            : 'Update Meeting'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

const MeetingCard = ({ meeting, setRefetch, enqueueSnackbar }) => {
    const classes = useStyles()
    const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
    const [isUpdateLoading, setIsUpdateLoading] = React.useState(false)
    const [openForm, setOpenForm] = React.useState(false)
    const [openShareDialog, setOpenShareDialog] = React.useState(false)
    const history = useHistory()

    const baseClientUrl = process.env.REACT_APP_CLIENT_URL
    const publicMeetingUrl = `${baseClientUrl}public/meet?meetingId=${meeting?.id}`
    const privateMeetingUrl = `${baseClientUrl}admin/meet?ready=${meeting?.id}`

    function formatMeetingTime(isoString) {
        const date = new Date(isoString)
        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
        const formattedDate = new Intl.DateTimeFormat(
            'en-US',
            dateOptions
        ).format(date)
        const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true }
        const formattedTime = new Intl.DateTimeFormat(
            'en-US',
            timeOptions
        ).format(date)

        return `${formattedDate} at ${formattedTime}`
    }

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text)
            enqueueSnackbar('Link copied to clipboard!', {
                variant: 'success',
            })
        } catch (err) {
            enqueueSnackbar('Failed to copy link', {
                variant: 'error',
            })
        }
    }

    const onCloseShareDialog = () => {
        setOpenShareDialog(false)
    }

    const handleShareLink = () => {
        const meetingType = meeting?.meeting_type
        switch (meetingType) {
            case 'public':
                copyToClipboard(publicMeetingUrl)
                return
            case 'private':
                copyToClipboard(privateMeetingUrl)
                return
            case 'both':
                setOpenShareDialog(true)
                return
            default:
                return
        }
    }

    const deleteMeeting = async (id) => {
        try {
            setIsDeleteLoading(true)
            await RemoteServices.deleteMeeting(id)
            enqueueSnackbar(`Meeting #${id} deleted successfully!`, {
                variant: 'success',
            })
            setRefetch(true)
        } catch (err) {
            enqueueSnackbar(err, { variant: 'error' })
        } finally {
            setIsDeleteLoading(false)
        }
    }

    const handleMeetingRedirect = (meetingId) => {
        history.push(`/admin/group-call?ready=${meetingId}`)
    }

    const toggleMeetingVisibility = async (meetingId) => {
        try {
            setIsUpdateLoading(true)
            await RemoteServices.updateMeeting(meetingId, {
                is_visible_to_student: !meeting.is_visible_to_student,
                meeting_id: meeting.meeting_id,
            })
            enqueueSnackbar(
                `Meeting #${meetingId} visibility updated successfully!`,
                { variant: 'success' }
            )
            setRefetch(true)
        } catch (err) {
            enqueueSnackbar(err, { variant: 'error' })
        } finally {
            setIsUpdateLoading(false)
        }
    }

    return (
        <>
            <Card className={classes.card}>
                <CardContent>
                    <div className={classes.header}>
                        <div className={classes.title}>
                            <Typography
                                variant="h5"
                                style={{ textTransform: 'capitalize' }}
                            >
                                {meeting?.name?.length > 12
                                    ? `${meeting.name.slice(0, 12)}...`
                                    : meeting.name}
                            </Typography>
                            <div className={classes.publicLabel}>
                                {(() => {
                                    switch (meeting.meeting_type) {
                                        case 'public':
                                            return (
                                                <>
                                                    <LockOpen fontSize="small" />
                                                    <Typography>
                                                        Public
                                                    </Typography>
                                                </>
                                            )

                                        case 'private':
                                            return (
                                                <>
                                                    <Lock fontSize="small" />
                                                    <Typography>
                                                        Private
                                                    </Typography>
                                                </>
                                            )
                                        case 'both':
                                            return (
                                                <>
                                                    <Group fontSize="small" />
                                                    <Typography>
                                                        Both
                                                    </Typography>
                                                </>
                                            )
                                        default:
                                            return (
                                                <>
                                                    <LockOpen fontSize="small" />
                                                    <Typography>
                                                        Public
                                                    </Typography>
                                                </>
                                            )
                                    }
                                })()}
                            </div>
                        </div>
                        {meeting.meeting_type === 'public' ? (
                            <Chip
                                label="Shared Link Access"
                                className={classes.publicChip}
                            />
                        ) : meeting.is_visible_to_student ? (
                            <Chip
                                label="Accessible"
                                className={classes.accessibleChip}
                            />
                        ) : (
                            <Chip
                                label="Restricted"
                                className={classes.restrictedChip}
                            />
                        )}
                    </div>

                    <div className={classes.meetingId}>
                        <Link fontSize="small" />
                        <Typography>{meeting.meeting_id}</Typography>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div className={classes.participants}>
                            <Person fontSize="small" />
                            <Typography>0 participants</Typography>
                        </div>
                        <Typography className={classes.timeInfo}>
                            {formatMeetingTime(meeting.meeting_start_time)}
                        </Typography>
                    </div>

                    <div className={classes.actions}>
                        <div className={classes.leftActions}>
                            <IconButton
                                size="small"
                                color="primary"
                                onClick={() => setOpenForm(true)}
                            >
                                <Edit />
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={() => deleteMeeting(meeting.id)}
                                disabled={isDeleteLoading}
                            >
                                {isDeleteLoading ? (
                                    <CircularProgress size={16} />
                                ) : (
                                    <Delete style={{ color: '#ff8989' }} />
                                )}
                            </IconButton>
                            <IconButton size="small" onClick={handleShareLink}>
                                <Share />
                            </IconButton>
                        </div>

                        <div className={classes.rightSection}>
                            {meeting.meeting_type !== 'public' ? (
                                <Switch
                                    color="primary"
                                    name="toggleCard"
                                    disabled={isUpdateLoading}
                                    checked={meeting.is_visible_to_student}
                                    onChange={() =>
                                        toggleMeetingVisibility(meeting.id)
                                    }
                                />
                            ) : null}
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() =>
                                    handleMeetingRedirect(meeting.meeting_id)
                                }
                                className={classes.joinButton}
                                startIcon={<Videocam />}
                            >
                                Join Meeting
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <MeetingFormDialog
                key={meeting.id}
                type={'edit'}
                meetingDetails={meeting}
                {...{
                    openForm,
                    setOpenForm,
                    setRefetch,
                    enqueueSnackbar,
                }}
            />
            <ShareDialog
                openShareDialog={openShareDialog}
                onCloseShareDialog={onCloseShareDialog}
                copyToClipboard={copyToClipboard}
                publicMeetingUrl={publicMeetingUrl}
                privateMeetingUrl={privateMeetingUrl}
            />
        </>
    )
}

const EmptyMeetingsScreen = ({ setOpenForm }) => {
    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '80dvh',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}
            >
                <IconButton color="primary">
                    <EventIcon
                        style={{
                            fontSize: 80,
                            mb: 2,
                        }}
                    />
                </IconButton>

                <Typography variant="h4" component="h1" gutterBottom>
                    No Meetings Yet
                </Typography>
                <Typography variant="body1" color="text.secondary" paragraph>
                    You haven't created any meetings. Start by creating your
                    first meeting!
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<Add fontSize="large" />}
                    onClick={() => setOpenForm(true)}
                >
                    Create New Meeting
                </Button>
            </Box>
        </Container>
    )
}

const ShareDialog = ({
    openShareDialog,
    onCloseShareDialog,
    copyToClipboard,
    publicMeetingUrl,
    privateMeetingUrl,
}) => {
    const classes = useMeetingShareStyles()

    return (
        <Dialog
            open={openShareDialog}
            onClose={onCloseShareDialog}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Share Meeting Link</DialogTitle>
            <DialogContent>
                <List>
                    <ListItem
                        button
                        onClick={() => copyToClipboard(publicMeetingUrl)}
                        className={classes.listItem}
                    >
                        <ListItemIcon>
                            <Public className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Copy Public Link"
                            secondary="Anyone can join with this link"
                        />
                        <FileCopy color="action" />
                    </ListItem>
                    <ListItem
                        button
                        className={classes.listItem}
                        onClick={() => copyToClipboard(privateMeetingUrl)}
                    >
                        <ListItemIcon>
                            <School className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Copy Student Link"
                            secondary="Only authenticated students can join"
                        />
                        <FileCopy color="action" />
                    </ListItem>
                </List>
            </DialogContent>
        </Dialog>
    )
}

export default TeacherSupport
