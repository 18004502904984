import React from 'react'
import {
    Avatar,
    Box,
    Button,
    Container,
    makeStyles,
    Paper,
    Typography,
} from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import {
    Mic,
    Videocam,
    EmojiEmotions,
    ScreenShare,
    PhoneDisabled,
    People,
    Message,
    InfoRounded,
    MicOff,
    VideocamOff,
    PanTool,
    Stars,
    School,
    Public,
} from '@material-ui/icons'
import PreMeetingScreen from './PreMeetingScreen'
import { useLocation, useHistory } from 'react-router-dom'
import { useDateTime } from 'hooks/use-date-time'
import { useWebrtc } from 'hooks/use-webrtc'
import { useGridLayout } from 'hooks/use-grid-layout'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        gap: theme.spacing(1),
        position: 'fixed',
        width: '100%',
        inset: '0px',
        backgroundColor: '#333',
        color: '#fff',
    },
    boxContainer: {
        width: '100%',
        maxWidth: '95%',
        maxHeight: 'calc(100vh - 64px)',
    },
    box: {
        display: 'grid',
        gap: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: theme.spacing(4),
        aspectRatio: '16 / 9',
    },
}))

const useVideoCardStyles = makeStyles((theme) => ({
    userContainer: {
        cursor: 'pointer',
        shadow: theme.shadows[3],
    },
    videoContainer: {
        backgroundColor: theme.palette.grey[800],
        borderRadius: theme.shape.borderRadius,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
    },
    video: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transform: 'scaleX(-1)',
    },
    videoMessage: {
        color: theme.palette.grey[400],
        fontSize: '1.2rem',
    },
    videoAvatar: {
        position: 'absolute',
        transform: 'translateX(-50%) translateY(-50%)',
        width: '8rem',
        height: '8rem',
        fontSize: '2rem',
        backgroundColor: '#8b4c70',
        textTransform: 'capitalize',
    },
    nameTag: {
        position: 'absolute',
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: theme.palette.common.white,
        padding: theme.spacing(0.5, 1),
        borderRadius: theme.shape.borderRadius,
        maxWidth: '80%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0.5),
    },
    muteIndicator: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: 'white',
        padding: theme.spacing(0.5),
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    handRaiseIndicator: {
        position: 'absolute',
        top: theme.spacing(1),
        left: theme.spacing(1),
        backgroundColor: 'rgba(255, 193, 7, 0.8)',
        color: 'black',
        padding: theme.spacing(0.5),
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        animation: '$pulse 1.5s infinite',
    },
    '@keyframes pulse': {
        '0%': {
            transform: 'scale(1)',
            boxShadow: '0 0 0 0 rgba(255, 193, 7, 0.4)',
        },
        '70%': {
            transform: 'scale(1.1)',
            boxShadow: '0 0 0 10px rgba(255, 193, 7, 0)',
        },
        '100%': {
            transform: 'scale(1)',
            boxShadow: '0 0 0 0 rgba(255, 193, 7, 0)',
        },
    },
    userTypeIcon: {
        fontSize: '1rem',
    },
    admin: {
        border: '2px solid #6e45e2',
        boxShadow: '0 2px 8px rgba(110, 69, 226, 0.15)',
    },
    student: {
        border: '2px solid #f6d365',
        boxShadow: '0 2px 8px rgba(246, 211, 101, 0.15)',
    },
    public: {
        border: '2px solid #84fab0',
        boxShadow: '0 2px 8px rgba(132, 250, 176, 0.15)',
    },
}))

const useVideoControlStyles = makeStyles((theme) => ({
    controlContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100vw',
        padding: '0.5rem 2rem',
    },
    toggleButtonGroup: {
        display: 'flex',
        gap: theme.spacing(1),
        backgroundColor: '#444',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        boxShadow: theme.shadows[3],
    },
    toggleButton: {
        border: 'none',
        borderRadius: theme.shape.borderRadius,
        color: '#fff',
        padding: theme.spacing(1),
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    endCall: {
        backgroundColor: 'red',
        color: '#fff',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1.5, 3),
        '&:hover': {
            backgroundColor: '#d32f2f',
        },
    },
}))

const GroupCall = () => {
    const classes = useStyles()
    const {
        localVideoRef,
        isAudioMuted,
        isVideoMuted,
        toggleAudio,
        toggleVideo,
        toggleRaiseHand,
        peersRef,
    } = useWebrtc()
    const [controls, setControls] = React.useState(() => ['mic', 'cam'])
    const location = useLocation()
    const gridRef = React.useRef(null)
    const peersWithStream = Array.from(peersRef.current?.values()).filter(
        (peerData) => peerData.stream
    )
    const TOTAL_PEERS = peersWithStream?.length + 1

    const { layout } = useGridLayout(gridRef, TOTAL_PEERS)

    const queryParams = new URLSearchParams(location.search)

    const isReady = queryParams.get('ready')
    const isJoin = queryParams.get('join')

    const userDetail = JSON.parse(localStorage.getItem('user'))
    const userId = userDetail?.id
    const VISIBLE_PEERS = Math.min(TOTAL_PEERS, layout.maxTiles)
    const isRaisingHand = controls.includes('raise-hand')

    if (isReady) {
        return (
            <PreMeetingScreen
                ref={localVideoRef}
                {...{
                    isAudioMuted,
                    toggleAudio,
                    toggleVideo,
                    isVideoMuted,
                }}
            />
        )
    } else if (!isJoin) {
        return null
    }

    return (
        <Container maxWidth={'xl'} className={classes.container}>
            <Box className={classes.boxContainer}>
                <div
                    className={classes.box}
                    ref={gridRef}
                    style={{
                        gridTemplateColumns: `repeat(var(--col-count), 1fr)`,
                        gridTemplateRows: `repeat(calc(${Math.ceil(
                            VISIBLE_PEERS / 'var(--col-count)'
                        )}), 1fr)`,
                    }}
                >
                    {/* local video */}
                    <MeetingVideoCard
                        ref={localVideoRef}
                        userName={'English Hub'}
                        userType="admin"
                        {...{
                            isAudioMuted,
                            isVideoMuted,
                            isRaisingHand,
                        }}
                    />

                    {/* peer videos */}
                    {Array.from(peersRef.current?.values()).map((peerData) => {
                        if (
                            String(peerData.id) === String(userId) ||
                            !peerData.stream
                        ) {
                            return null
                        }
                        return (
                            <PeerVideo key={peerData.id} peerData={peerData} />
                        )
                    })}
                </div>
            </Box>

            {/* call controls */}
            <MeetingVideoControl
                {...{
                    toggleRaiseHand,
                    setControls,
                    toggleAudio,
                    toggleVideo,
                    isAudioMuted,
                    isVideoMuted,
                    controls,
                }}
            />
        </Container>
    )
}

const MeetingVideoControl = ({
    toggleRaiseHand,
    setControls,
    toggleAudio,
    toggleVideo,
    isAudioMuted,
    isVideoMuted,
    controls,
}) => {
    const classes = useVideoControlStyles()
    const history = useHistory()

    const { time } = useDateTime()
    const handleChange = (event, newControls) => {
        const isRaiseHand = newControls.includes('raise-hand')
        if (isRaiseHand) {
            toggleRaiseHand(true)
        } else if (!isRaiseHand) {
            toggleRaiseHand(false)
        }
        setControls(newControls)
    }

    const handleEndCall = () => {
        history.push('/admin/meeting')
    }

    return (
        <Box className={classes.controlContainer}>
            <div>
                <span>{time}</span>
                <span> | teacher-support</span>
            </div>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <ToggleButtonGroup
                    className={classes.toggleButtonGroup}
                    color="primary"
                    value={controls}
                    onChange={handleChange}
                >
                    <ToggleButton
                        className={classes.toggleButton}
                        onClick={toggleAudio}
                        value="mic"
                    >
                        {isAudioMuted ? <MicOff /> : <Mic />}
                    </ToggleButton>
                    <ToggleButton
                        className={classes.toggleButton}
                        onClick={toggleVideo}
                        value="cam"
                    >
                        {isVideoMuted ? <VideocamOff /> : <Videocam />}
                    </ToggleButton>
                    <ToggleButton
                        className={classes.toggleButton}
                        value="emoji"
                    >
                        <EmojiEmotions />
                    </ToggleButton>
                    <ToggleButton
                        className={classes.toggleButton}
                        value="share-screen"
                    >
                        <ScreenShare />
                    </ToggleButton>
                    <ToggleButton
                        className={classes.toggleButton}
                        value="raise-hand"
                    >
                        <PanTool />
                    </ToggleButton>
                </ToggleButtonGroup>
                <Button className={classes.endCall} onClick={handleEndCall}>
                    <PhoneDisabled />
                </Button>
            </Box>

            <Box style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                <ToggleButton className={classes.toggleButton} value="info">
                    <InfoRounded />
                </ToggleButton>

                <ToggleButton className={classes.toggleButton} value="people">
                    <People />
                </ToggleButton>

                <ToggleButton className={classes.toggleButton} value="message">
                    <Message />
                </ToggleButton>
            </Box>
        </Box>
    )
}

const MeetingVideoCard = React.forwardRef(
    (
        { isVideoMuted, userName, isRaisingHand, isAudioMuted, userType },
        ref
    ) => {
        const classes = useVideoCardStyles()
        const getUserType = () => {
            switch (userType) {
                case 'admin':
                    return {
                        className: classes.admin,
                        Icon: <Stars className={classes.userTypeIcon} />,
                    }
                case 'student':
                    return {
                        className: classes.student,
                        Icon: <School className={classes.userTypeIcon} />,
                    }
                default:
                    return {
                        className: classes.public,
                        Icon: <Public className={classes.userTypeIcon} />,
                    }
            }
        }

        return (
            <Box className={classes.userContainer}>
                <Paper
                    elevation={2}
                    className={` ${classes.videoContainer}
          ${getUserType().className}
          `}
                >
                    {isVideoMuted ? (
                        <Typography className={classes.videoMessage}>
                            <Avatar
                                color="primary"
                                className={classes.videoAvatar}
                            >
                                {userName
                                    .split(' ')
                                    .map((name) => name[0])
                                    .join('')}
                            </Avatar>
                        </Typography>
                    ) : (
                        <video ref={ref} className={classes.video} autoPlay />
                    )}
                    <Typography
                        variant="body2"
                        className={`${classes.nameTag}`}
                    >
                        {getUserType().Icon}
                        {userName}
                    </Typography>
                    {isAudioMuted ? (
                        <div className={classes.muteIndicator}>
                            <MicOff fontSize="small" />
                        </div>
                    ) : null}
                    {isRaisingHand ? (
                        <div className={classes.handRaiseIndicator}>
                            <PanTool fontSize="small" />
                        </div>
                    ) : null}
                </Paper>
            </Box>
        )
    }
)

const PeerVideo = ({ peerData }) => {
    const [raiseHand, setRaiseHand] = React.useState('')
    const [peerMutedDetails, setPeerMutedDetails] = React.useState({})
    const { id, stream, peer } = peerData

    peer.on('data', (data) => {
        const jsonData = JSON.parse(data)
        if (jsonData.type) {
            switch (jsonData.type) {
                case 'isMuted':
                    setPeerMutedDetails({
                        isVideoMuted: jsonData.isVideoMuted,
                        isAudioMuted: jsonData.isAudioMuted,
                    })
                    break
                case 'raiseHand':
                    setRaiseHand(jsonData.isRaiseHand)
                    break
                default:
                    break
            }
        }
    })

    return (
        <>
            <MeetingVideoCard
                key={id}
                ref={(videoElement) => {
                    if (videoElement && stream) {
                        videoElement.srcObject = stream
                    }
                }}
                isVideoMuted={peerMutedDetails?.isVideoMuted}
                isAudioMuted={peerMutedDetails?.isAudioMuted}
                isRaisingHand={raiseHand}
                userName={peerData?.detail?.full_name}
                userType={peerData?.detail?.user_type}
            />
        </>
    )
}

export default GroupCall
